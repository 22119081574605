import * as React from 'react'
import * as Styles from './magaziny.module.sass'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from '../components/seo'

export const query = graphql`
fragment magazineImage on File {
  childImageSharp {
    gatsbyImageData(layout: FULL_WIDTH)
  }
}
query {
  jg: file(relativePath: { eq: "magaziny/jg.jpg" }) {
    ...magazineImage
  }
  rgh: file(relativePath: { eq: "magaziny/rgh.jpg" }) {
    ...magazineImage
  }
  gc: file(relativePath: { eq: "magaziny/gc.jpg" }) {
    ...magazineImage
  }
  rif: file(relativePath: { eq: "magaziny/rif.jpg" }) {
    ...magazineImage
  }
}
`

const Magazin = ({ ...props }) => {
  const image = getImage(props.img)
  return (<>
    <div className={`${Styles.magaz}`}>
      <GatsbyImage image={image} alt={`${props.title} - ${props.adress1}, ${props.adress2}`} />
      <div className={`${Styles.floating}`}>
        <h3 className={`is-size-4`}>{props.title}</h3>
        <div className={`${Styles.adressbar}`}>
          <p>{props.adress1}</p>{props.adress2}
        </div>
      </div>
    </div>
  </>)
}

const MagazinyPage = ({ data }) => {
  return (
    <>
      <Seo
        title={`Где можно купить изделия касторских`}
      />
      <h1 className={`is-size-1 is-size-3-touch mainheader`}>Где можно купить<br />изделия касторских</h1>
      <div className={`columns mainwrapper ${Styles.wrapper}`}>
        <div className={`column ${Styles.col}`}>
          <Magazin img={data.jg} title={`Ювелирная галерея`} adress1={`Кострома, улица Лесная, 11Б`} />
        </div>
        <div className={`column ${Styles.col}`}>
          <Magazin img={data.rgh} title={`Российский Ювелирный Дом`} adress1={`Санкт-Петербург, Невский проспект, 29`} />
        </div>
      </div>
      <div className={`columns mainwrapper ${Styles.wrapper}`}>
        <div className={`column ${Styles.col}`}>
          <Magazin img={data.rif} title={`РИФ`} adress1={`Санкт-Петербург, Гражданский проспект, 41`}  adress2={`ТЦ Академ парк, цокольный этаж`}/>
        </div>
        {/* <div className={`column ${Styles.col}`}>
          <Magazin img={data.gc} title={`Golden Choice`} adress1={`Ивановская область, г. Плес`} adress2={`Торговые ряды, 4, помещение 10`}/>
        </div> */}
      </div>
    </>
  )
}
export default MagazinyPage
